/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import {
  Hero5,
  Service,
  ServiceItems,
  QuickScan,
  Reference,
  HeadingLayout,
} from 'components/elements'

const PageTemplate = ({
  data: {
    page: { wordpress_id: wordpressId, path, title, acf, yoast_meta: yoast },
  },
}) => {
  return (
    <Layout activeHeader="Diensten">
      <SEO yoast={yoast} path={path} />

      <Hero5
        fields={acf.banner}
      />

      {acf.blocks.map((b, index) => (
        <div key={index} className="py-lg-5 mt-5">
          <div className="pt-5">
            <Service
              fields={b}
              direction={index % 2 === 0 ? 'right' : 'left'}
              backgroundTitle={b.background_title}
            />

            <a id="quickscan" />

          </div>
        </div>
      ))}
      
      <div className="py-lg-5 mt-5">
        <div className="pt-5">
          <HeadingLayout heading={acf.blok_2.background_title}>
            {acf.form && acf.form.gravity_form_id ? (
              <QuickScan
                fields={{ ...acf.blok_2, ...{ form: acf.form } }}
                subject={title}
              />
            ) : (
              <QuickScan
                fields={acf.blok_2}
                subject={title}
              />
            )}
            
          </HeadingLayout>
        </div>
      </div>

      <div className="py-lg-5 mt-5">
        <div className="pt-5">
          <HeadingLayout heading={acf.blok_3.background_title} direction="right">
            <Reference
              fields={acf.blok_3}
            />
          </HeadingLayout>
        </div>
      </div>

      <div className="py-lg-5 mt-5">
        <div className="pt-5">
          <HeadingLayout heading={acf.references.background_description}>
            <ServiceItems id={wordpressId} type={2} />
          </HeadingLayout>
        </div>
      </div>

      <div className="py-5" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      wordpress_id

      acf {
        banner {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        blocks {
          background_title
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }

        blok_2 {
          background_title
          description
          cta {
            relation {
              wordpress_id
              title
            }
            description
          }
        }

        blok_3 {
          background_title
          description
          link {
            title
            url
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        form {
          gravity_form_id
        }

        references {
          background_description
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
